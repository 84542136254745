import { AppContext } from 'providers/context/App'
import { Dispatch, SetStateAction, useContext, useEffect } from 'react'
import { MEMBERSHIP_LASER_FACIAL_PRICE } from 'utils/constants/Helpers'
import { MONTHLY_SECOND_FACIAL_PRICE } from 'utils/constants/Membership'
import { formattedCost, getFacialType } from 'utils/helper-functions'
import { getEnhancementPrice } from 'utils/helper-functions/membershipInfo'
import { AppData } from 'utils/types/appTypes'
import useMembership from 'views/hooks/useMembership'

type Props = {
  setTotal: Dispatch<SetStateAction<number>>
  isUpsell?: boolean
}

const AppointmentSubtotal = ({ isUpsell, setTotal }: Props) => {
  const { appMasterData } = useContext(AppContext)
  const {
    isMember,
    isMonthlyMember,
    isBiMonthlyMember,
    isBiMonthlyVoucherActive,
    isMonthlyVoucherActive,
  } = useMembership()

  const isLaserFacial = appMasterData.isLaserFacial

  const purchasedMembership = appMasterData.cart.purchasedMembership

  const isLaserConsultation =
    appMasterData.selectedFacial.toLowerCase() === 'laser lite + consultation'

  // calculating subtotal when enhacement removed or membership removed
  useEffect(() => {
    getSubTotal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appMasterData.selectedEnhancements,
    appMasterData.cart.purchasedMembership,
  ])

  const calculateFacialPrice = (): number => {
    if (!isMember || isLaserFacial) {
      return appMasterData.category.categoryObj?.listPrice ?? 0
    }

    if (isMonthlyMember) {
      return isMonthlyVoucherActive
        ? MONTHLY_SECOND_FACIAL_PRICE
        : appMasterData.membershipData.listPrice
    }

    if (isBiMonthlyMember) {
      return isBiMonthlyVoucherActive
        ? (appMasterData.category.categoryObj?.listPrice ?? 0)
        : appMasterData.membershipData.listPrice
    }

    return appMasterData.category.categoryObj?.listPrice ?? 0
  }

  const calculateEnhancementsPrice = (basePrice: number): number => {
    return isMember && !isLaserFacial
      ? getEnhancementPrice(basePrice, isBiMonthlyMember)
      : basePrice
  }

  const getSubTotal = (): number => {
    const membershipCost =
      isUpsell || purchasedMembership
        ? appMasterData.membershipData.listPrice
        : 0

    let enhancementsPrice = appMasterData.selectedEnhancements.reduce(
      (acc, curr) => acc + (curr.listPrice ?? 0),
      0
    )

    let facialPrice = calculateFacialPrice()
    enhancementsPrice = calculateEnhancementsPrice(enhancementsPrice)

    if (isUpsell || purchasedMembership) {
      facialPrice = 0
      enhancementsPrice = getEnhancementPrice(
        enhancementsPrice,
        isBiMonthlyMember
      )
    }

    const discountPrice = appMasterData.cart.summary.discountAmount
    const subTotal =
      membershipCost + facialPrice + enhancementsPrice - discountPrice

    setTotal(subTotal)
    return subTotal
  }

  // Monthly membership price. Only show if upsell or purchased
  const renderMembershipPrice = (
    appMasterData: AppData,
    isUpsell: boolean | undefined
  ) => {
    if (purchasedMembership || isUpsell) {
      return (
        <div className="price-item">
          <span className="item-text">Monthly Membership</span>
          <div>
            <span className="cost mono-1">
              {formattedCost(appMasterData.membershipData.listPrice)}
            </span>
          </div>
        </div>
      )
    }
    return null
  }

  // Facial price line, if member or upsell or purchased, show discounted price as well
  const renderFacialPrice = (
    appMasterData: AppData,
    isLaserFacial: boolean,
    isUpsell: boolean | undefined,
    purchasedMembership: boolean
  ) => {
    const facialPrice = appMasterData.category.categoryObj?.listPrice as number
    const getMemberServicePrice = () => {
      if (!isLaserFacial && !isLaserConsultation) { // NOSONAR
        return formattedCost(
          Math.max(
            isMonthlyVoucherActive
              ? MONTHLY_SECOND_FACIAL_PRICE
              : appMasterData.membershipData.listPrice,
            8500
          )
        )
      } else if (isLaserFacial && !isLaserConsultation) {
        return formattedCost(MEMBERSHIP_LASER_FACIAL_PRICE, true, 1)
      }
    }

    return (
      <div className="price-item">
        <span
          className={`item-text ${purchasedMembership && 'strike-through'}`}
        >
          {getFacialType(appMasterData.selectedFacial)}
        </span>
        <div>
          <span
            className={`cost mono-1 ${
              ((isMember &&
                !isLaserConsultation &&
                (isMonthlyMember || !isBiMonthlyVoucherActive)) ||
                isUpsell ||
                purchasedMembership) &&
              'strike-through'
            }`}
          >
            {formattedCost(facialPrice)}
          </span>
          {(isUpsell || purchasedMembership) && (
            <span className="cost mono-1 member-price">{formattedCost(0)}</span>
          )}
          {!isUpsell &&
            !purchasedMembership &&
            isMember &&
            (isMonthlyMember || !isBiMonthlyVoucherActive) && (
              <span className="cost mono-1 member-price success">
                {/* reduce upto $31 from facial price for members. minimum price should be $85. */}
                {/* facial price - $31 OR $85. whichever is higher */}
                {getMemberServicePrice()}
              </span>
            )}
        </div>
      </div>
    )
  }

  // Enhancements price list, if member or upsell or purchased, show discounted price as well
  const renderEnhancementsPriceList = (
    appMasterData: AppData,
    isLaserFacial: boolean,
    isMember: boolean,
    isUpsell: boolean | undefined,
    purchasedMembership: boolean,
    isBiMonthlyMember: boolean
  ) => {
    return (
      <>
        {!isLaserFacial &&
          appMasterData.selectedEnhancements.map((item) => (
            <div className="price-item" key={item.id}>
              <span className="item-text">
                {item.titleVisible || item.name}
              </span>
              <div>
                <span
                  className={`cost mono-1 ${
                    isMember || isUpsell || purchasedMembership
                      ? 'strike-through'
                      : ''
                  }`}
                >
                  {formattedCost(item.listPrice)}
                </span>
                {(isMember || isUpsell || purchasedMembership) && (
                  <span className="cost mono-1 member-price success">
                    {formattedCost(
                      getEnhancementPrice(item.listPrice, isBiMonthlyMember)
                    )}
                  </span>
                )}
              </div>
            </div>
          ))}
      </>
    )
  }

  // Membership benefit. remaining amount to be shown as discount to make the price of facial as per requirements
  const renderMembershipBenefit = (
    isMember: boolean,
    isLaserFacial: boolean
  ) => {
    return (
      <>
        {isMember && !isLaserConsultation && isMonthlyMember && (
          <div className="price-item">
            <span className="item-text success">
              Member Benefit {!isLaserFacial && 'Applied!'}
            </span>
            {isLaserFacial && (
              <div>
                <span className="cost mono-1 member-price success">
                  40% savings
                </span>
              </div>
            )}
          </div>
        )}
      </>
    )
  }

  // Promo code discount line, show as received from getCart call
  const renderPromoCodeDiscountLine = (appMasterData: AppData) => {
    return (
      <>
        {appMasterData.cart.isPromoCodeApplied && (
          <div className="price-item">
            <span className="item-text success">
              {appMasterData.cart.promoCodes.map((d) => d.code).join(', ')}
            </span>
            <span className="cost mono-1 success">
              -{formattedCost(appMasterData.cart.summary.discountAmount)}
            </span>
          </div>
        )}
      </>
    )
  }

  // Total line, show only if more then one lines from above is shown
  const renderTotalLine = (
    appMasterData: AppData,
    isUpsell: boolean | undefined,
    purchasedMembership: boolean
  ) => {
    return (
      <>
        {(!!appMasterData.selectedEnhancements.length ||
          (isMember && !isLaserFacial) ||
          isUpsell ||
          purchasedMembership ||
          appMasterData.cart.isPromoCodeApplied) && (
          <div className="price-item total">
            <span
              className={`item-text ${
                (isUpsell || purchasedMembership) && 'success'
              }`}
            >
              {!purchasedMembership && isUpsell ? 'Member Price' : 'Total'}
            </span>
            <span className={`cost mono-1 success`}>
              {formattedCost(Math.max(getSubTotal(), 0))}
            </span>
          </div>
        )}
      </>
    )
  }

  // total amount to be paid on checkout. only membership purchase is charged on card on checkout
  const renderAmountPaidToday = (
    appMasterData: AppData,
    purchasedMembership: boolean
  ) => {
    if (purchasedMembership) {
      return (
        <>
          <div className="price-item">
            <span className="item-text">Amount Paid Today</span>
            <div>
              <span className="cost mono-1">
                {formattedCost(appMasterData.membershipData.listPrice)}
              </span>
            </div>
          </div>
          <span className="body-2 note">
            Additional enhancement charges will be processed after your
            appointment.
          </span>
        </>
      )
    }
    return null
  }

  return (
    <div>
      {renderMembershipPrice(appMasterData, isUpsell)}
      {renderFacialPrice(
        appMasterData,
        isLaserFacial,
        isUpsell,
        purchasedMembership
      )}
      {renderEnhancementsPriceList(
        appMasterData,
        isLaserFacial,
        isMember,
        isUpsell,
        purchasedMembership,
        isBiMonthlyMember
      )}
      {renderMembershipBenefit(isMember, isLaserFacial)}
      {renderPromoCodeDiscountLine(appMasterData)}
      {renderTotalLine(appMasterData, isUpsell, purchasedMembership)}
      {renderAmountPaidToday(appMasterData, purchasedMembership)}
    </div>
  )
}

export default AppointmentSubtotal
