export enum AXIOS_METHODS {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum HEALTH_CHECK {
  YES = 'YES',
  NO = 'NO',
  NULL = 'NULL',
}

export enum ENV {
  DEV = 'development',
  STAG = 'staging',
  PROD = 'production',
}

export enum DISCOUNT {
  THIRTY = 30,
  FIFTY = 50,
}
