import { Button } from '@mui/material'
import React from 'react'
interface FacialContinueButtonProps {
  selectedFacial: string
  proceedNextScreen: () => void
}

export default function FacialContinueButton({
  selectedFacial,
  proceedNextScreen,
}: Readonly<FacialContinueButtonProps>) {
  return (
    <div className="facial-container">
      <div className="footer-continue">
        <Button
          fullWidth
          variant="contained"
          disabled={!selectedFacial}
          disableElevation
          className="app-btn"
          onClick={proceedNextScreen}
        >
          Continue
        </Button>
      </div>
    </div>
  )
}
