import { useState, useContext } from 'react'

import { services } from 'utils/constants/services'
import { conditionalClasses, formattedCost } from 'utils/helper-functions'
import { Facial } from 'utils/types/appTypes'
import {
  MEMBERSHIP_MAX_FACIAL_DISCOUNT,
  MEMBERSHIP_LASER_FACIAL_PRICE,
} from 'utils/constants/Helpers'
import { AppContext } from 'providers/context/App'
import useMembership from 'views/hooks/useMembership'

interface FacialCardProps {
  selectedFacial: string
  facial: Facial
  onSelectFacial: (facial: Facial) => void
}
const FacialCard = ({
  selectedFacial,
  facial,
  onSelectFacial,
}: FacialCardProps) => {
  const { appMasterData } = useContext(AppContext)
  const [collapse, setCollapse] = useState(false)
  const { isMember, isMonthlyMember } = useMembership()
  const facialService = services.find((service) => service.name === facial.name)

  return (
    <button
      className={`facial-card ${
        selectedFacial === facial.name && 'facial-selected'
      }`}
      key={facial.name}
      onClick={() => onSelectFacial(facial)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onSelectFacial(facial)
        }
      }}
    >
      <div className="facial-details">
        <div>
          <h3 className="facial-name">{facial.name}</h3>
          <p className="facial-description">
            {collapse ? facialService?.long : facialService?.short}
            {!collapse && (
              <>
                {'.. '}
                <button
                  className="option-more-button"
                  onClick={() => setCollapse(!collapse)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      setCollapse(!collapse)
                    }
                  }}
                >
                  More
                </button>
              </>
            )}
          </p>
        </div>
        <div className="facial-price">
          <div
            className={conditionalClasses(
              isMember,
              conditionalClasses(
                !facialService?.isMembershipPrice ||
                  (facialService?.isLaser && !isMonthlyMember),
                '',
                'line-through'
              ),
              '',
              'original-price'
            )}
          >
            {formattedCost(facial.listPrice, false, 100)}
          </div>
          {!facialService?.hideMemberPrice && (
            <>
              {!isMember && (
                <div className="non-member">
                  <span>
                    {facialService?.isLaser
                      ? formattedCost(MEMBERSHIP_LASER_FACIAL_PRICE, false, 1)
                      : formattedCost(
                          Math.max(
                            +facial.listPrice - MEMBERSHIP_MAX_FACIAL_DISCOUNT,
                            8500
                          ),
                          false
                        )}
                  </span>
                  <span>MONTHLY MEMBER</span>
                </div>
              )}
              {isMember && (
                <div className="member-price">
                  <span>
                    {facialService?.isLaser &&
                      isMonthlyMember &&
                      formattedCost(MEMBERSHIP_LASER_FACIAL_PRICE, false, 1)}

                    {!facialService?.isLaser &&
                      formattedCost(
                        Math.max(appMasterData.membershipData.listPrice, 8500),
                        false
                      )}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </button>
  )
}

export default FacialCard
