import { AppContext } from 'providers/context/App'
import { useContext } from 'react'
import { MEMBERSHIP_TYPES } from 'utils/constants/Membership'
import { getLatestAppointment } from 'utils/helper-functions'
import { memberSecondAppointmentCheck } from 'utils/helper-functions/membershipInfo'

interface UseMembershipReturnType {
  isMember: boolean
  isMonthlyMember: boolean
  isBiMonthlyMember: boolean
  isAllowBiMonthlyOffer: boolean
  isMonthlyVoucherActive: boolean
  isBiMonthlyVoucherActive: boolean
}

const useMembership = (): UseMembershipReturnType => {
  const { appMasterData } = useContext(AppContext)

  const isLaserFacial = appMasterData.isLaserFacial
  const isMember = appMasterData.userInfo.is_member
  const isBiMonthlyMember =
    isMember && appMasterData.userMembershipType === MEMBERSHIP_TYPES.BI_MONTHLY
  const isMonthlyMember =
    isMember && appMasterData.userMembershipType === MEMBERSHIP_TYPES.MONTHLY

  const latestAppt = getLatestAppointment(
    appMasterData.previousBookedAppointments
  )

  const isMemberSecondAppointment = memberSecondAppointmentCheck(
    appMasterData.userActiveMembership,
    latestAppt
  )

  const isBiMonthlyVoucherActive =
    isBiMonthlyMember && isMemberSecondAppointment

  const isAllowBiMonthlyOffer =
    !isLaserFacial && !isMonthlyMember && isBiMonthlyVoucherActive

  const isMonthlyVoucherActive = isMonthlyMember && isMemberSecondAppointment

  return {
    isMember,
    isMonthlyMember,
    isBiMonthlyMember,
    isAllowBiMonthlyOffer,
    isBiMonthlyVoucherActive,
    isMonthlyVoucherActive,
  }
}

export default useMembership
