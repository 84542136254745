import React from 'react'
import * as Sentry from '@sentry/react'
import { Button } from '@mui/material'
import { AppContext } from 'providers/context/App'
import { AppointmentConfirmationContext } from 'providers/context/AppointmentConfirmation'
import {
  addCardToCart,
  checkoutCart,
  reserveTimeslotToCart,
  selectCardMethod,
  updateClientInfo,
} from 'services/Cart'
import { addPaymentMethods, generatePaymentToken } from 'services/Payment'
import { PaymentTokenizeResponse } from 'utils/types/appointmentTypes'
import {
  getBrandName,
  getBrandNameConverter,
} from 'utils/helper-functions/cardBrands'
import './appointment.scss'
import { useLoading } from 'providers/context/Loading'
import { CheckoutCartResponse, ClientInformation } from 'utils/types/cartTypes'
import { useNavigate } from 'react-router'
import {
  triggerPurchaseConversionEvent,
  triggerFranchiseeConversionEvent,
  agreeMembershipEventHorizon,
  upfPushOrder,
} from 'services/Analytics'
import {
  chooseToken,
  conditionalClasses,
  containSpecialChar,
  cleanPhoneNumber,
  formattedCost,
  getErrorMessage,
  textTrimmer,
} from 'utils/helper-functions'
import {
  APPOINTMENT_MESSAGES,
  CART_NOT_CREATED,
  GENERAL_ERROR_MESSAGE,
} from 'utils/constants/Messages'
import { ErrorContext, ErrorInitialData } from 'providers/context/Error'
import { useSnackbar } from 'notistack'
import {
  MEMBERSHIP_UPCOMING_APPOINTMENT,
  MEMBERSHIP,
  MEMBERSHIP_NO_APPOINTMENT,
  CONFIRM_APPOINTMENT_ROUTE,
  LASER_CONFIRM_APPOINTMENT_ROUTE,
  DEFAULT_CURRENCY,
} from 'utils/constants/Helpers'
import { getUserAppointments } from 'services/User'
import {
  addSelectedPurchasableItemToCart,
  signMembershipAgreement,
} from 'services/Membership'

type Props = {
  isMembership?: boolean
}

const AppointmentConfirmBtn = ({ isMembership }: Props) => {
  const { setLoading } = useLoading()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { updateErrorData } = React.useContext(ErrorContext)
  const { appMasterData, updateData } = React.useContext(AppContext)
  const { appointmentConfMasterData } = React.useContext(
    AppointmentConfirmationContext
  )

  const checkIsLaserAndPreviousApp =
    appMasterData.selectedFacial.toLowerCase() === 'laser facial' &&
    appMasterData.previousBookedAppointments.length < 1

  const validateCardFields = () => {
    if (appointmentConfMasterData.isExistingCard) return false

    const fieldvalid = {
      name: '',
      number: '',
      exp_month: '',
      exp_year: '',
      cvv: '',
      brand: '',
      zip_code: '',
    }

    if (!textTrimmer(appointmentConfMasterData.newCard.name))
      fieldvalid.name = APPOINTMENT_MESSAGES.REQUIRED('Name')
    else if (!containSpecialChar(appointmentConfMasterData.newCard.name))
      fieldvalid.name = APPOINTMENT_MESSAGES.INVALID_NAME

    if (!appointmentConfMasterData.newCard.number)
      fieldvalid.number = APPOINTMENT_MESSAGES.REQUIRED('Account Number')

    if (!appointmentConfMasterData.newCard.exp_month)
      fieldvalid.exp_month = APPOINTMENT_MESSAGES.REQUIRED('Expiry Month')

    if (!appointmentConfMasterData.newCard.exp_year)
      fieldvalid.exp_year = APPOINTMENT_MESSAGES.REQUIRED('Expiry Year')

    if (!appointmentConfMasterData.newCard.cvv)
      fieldvalid.cvv = APPOINTMENT_MESSAGES.REQUIRED('CVV')

    if (!textTrimmer(appointmentConfMasterData.newCard.zip_code))
      fieldvalid.zip_code = APPOINTMENT_MESSAGES.REQUIRED('Zip Code')

    return Object.values(fieldvalid).find((x) => x !== '')
  }

  const addTimeslotToCart = async () => {
    if (!appointmentConfMasterData.isExistingCard) {
      // Generate Token
      const generateTokenPayload = {
        card: {
          name: appointmentConfMasterData.newCard.name,
          number: appointmentConfMasterData.newCard.number,
          cvv: appointmentConfMasterData.newCard.cvv,
          exp_month: appointmentConfMasterData.newCard.exp_month,
          exp_year: appointmentConfMasterData.newCard.exp_year,
          address_postal_code: appointmentConfMasterData.newCard.zip_code,
        },
      }

      const generatedToken = await generatePaymentToken(generateTokenPayload)
      // Add card to the list

      return generatedToken
    }
  }

  const membershipCheckout = async (cartCheckout: CheckoutCartResponse) => {
    if (cartCheckout.checkoutCart?.cart?.errors?.length !== 0) {
      enqueueSnackbar(GENERAL_ERROR_MESSAGE, {
        variant: 'error',
      })
      return
    }

    updateData({
      cart: {
        ...appMasterData.cart,
        isPromoCodeApplied: false,
        promoCodes: [],
      },
    })
    // Analytics
    triggerPurchaseConversionEvent()
    //appointment history
    const userAppointment = await getUserAppointments()
    navigate(
      `${MEMBERSHIP}${
        userAppointment.length
          ? MEMBERSHIP_UPCOMING_APPOINTMENT
          : MEMBERSHIP_NO_APPOINTMENT
      }`
    )
  }

  const facialCheckout = (cartCheckout: CheckoutCartResponse) => {
    if (cartCheckout.checkoutCart?.appointments?.length) {
      updateData({
        cart: {
          ...appMasterData.cart,
          isPromoCodeApplied: false,
          promoCodes: [],
          appointmentId:
            cartCheckout.checkoutCart.appointments[0].appointmentId,
        },
      })
      navigate(
        appMasterData.isLaserFacial
          ? LASER_CONFIRM_APPOINTMENT_ROUTE
          : CONFIRM_APPOINTMENT_ROUTE
      )
    } else {
      enqueueSnackbar(GENERAL_ERROR_MESSAGE, {
        variant: 'error',
      })
    }
  }

  // Save Payment Method/Card Details
  const saveNewCard = async (token: string) => {
    if (appointmentConfMasterData.isExistingCard) return

    const last4CardNo = String(appointmentConfMasterData.newCard.number).slice(
      -4
    )

    const addNewCardPayload = {
      card: {
        name: appointmentConfMasterData.newCard.name,
        number: last4CardNo,
        brand: getBrandNameConverter(
          getBrandName(appointmentConfMasterData.newCard.number)
        ),
        exp_month: appointmentConfMasterData.newCard.exp_month,
        exp_year: appointmentConfMasterData.newCard.exp_year,
        token: token,
        default: false,
      },
    }
    try {
      await addPaymentMethods(addNewCardPayload)
    } catch (error) {
      const e = error as ErrorInitialData
      updateErrorData(e)
    }
  }

  const updateClientInfoAndCheckoutCard = async (token: string) => {
    const { selectedCard, newCard } = appointmentConfMasterData
    // update client info
    const clientInfo: ClientInformation = {
      email: appMasterData.userInfo.email,
      firstName:
        appMasterData.userInfo.first_name || selectedCard?.name || newCard.name,
      lastName:
        appMasterData.userInfo.last_name || selectedCard?.name || newCard.name,
      phoneNumber: '',
    }
    if (appMasterData?.userInfo?.phone_number)
      clientInfo.phoneNumber = cleanPhoneNumber(
        appMasterData?.userInfo?.phone_number
      )

    await updateClientInfo(
      appMasterData.cart.cartId,
      clientInfo,
      appointmentConfMasterData.note
    )

    // checkout cart
    const cartCheckout = await checkoutCart(appMasterData.cart.cartId)

    // push upfluence event
    upfPushOrder({
      order_id:
        cartCheckout.checkoutCart.appointments?.[0]?.appointmentId ?? '',
      order_name: appMasterData.category.categoryObj?.name ?? '',
      amount: appMasterData.category.categoryObj?.listPrice ?? 0,
      items: appMasterData.selectedEnhancements.map((sE) => ({
        amount: sE.listPrice,
        name: sE.name,
        currency: DEFAULT_CURRENCY,
      })),
      customer: {
        customer_id: appMasterData.userInfo.boulevard_id,
        email: appMasterData.userInfo.email,
        first_name: appMasterData.userInfo.first_name,
        last_name: appMasterData.userInfo.last_name,
      },
    })

    // Saving the new payment card details after appointment success
    await saveNewCard(token)

    if (isMembership) {
      await membershipCheckout(cartCheckout)
    } else {
      facialCheckout(cartCheckout)
    }

    // Gtag analytic events
    const appointments = cartCheckout.checkoutCart.appointments
    if (appointments.length > 0) {
      triggerPurchaseConversionEvent(appointments?.[0]?.appointmentId)
      triggerFranchiseeConversionEvent(appointments?.[0]?.appointmentId)
    }
  }

  const onConfirm = async () => {
    try {
      setLoading(true)
      const validation = validateCardFields()

      if (validation) {
        setLoading(false)
        return enqueueSnackbar(validation, {
          variant: 'error',
        })
      }

      if (!(appMasterData.cart.cartId && appMasterData.userInfo.email)) {
        enqueueSnackbar(CART_NOT_CREATED, {
          variant: 'error',
        })
        return navigate('/')
      }
      let generateToken: PaymentTokenizeResponse = {
        success: false,
        token: '',
      }

      if (!isMembership) {
        // Add timeslot to cart
        await reserveTimeslotToCart(
          appMasterData.cart.cartId,
          appMasterData.selectedTime.id
        )
      }

      // Membership flow - sign agreement
      if (isMembership && appMasterData.isAgree) {
        await signMembershipAgreement(appMasterData.isAgree)
      }

      // Add membership to cart
      if (appMasterData.cart.purchasedMembership) {
        await addSelectedPurchasableItemToCart(
          appMasterData.cart.cartId,
          appMasterData.membershipData.id
        )
        // Membership upsell flow - sign agreement
        await signMembershipAgreement(true)
      }

      /* Set payment info to the cart
      1) If existing use card token
      2) add new card and use that token to submit
    */
      const generatedToken = await addTimeslotToCart()
      if (generatedToken) generateToken = generatedToken

      const tokenData = chooseToken(
        appointmentConfMasterData,
        generateToken.token
      )
      // Add payment card to cart
      const cardtoCart = await addCardToCart(
        appMasterData.cart.cartId,
        tokenData
      )

      // Select Payment method for checkout in cart
      if (!isMembership) {
        const paymentMethodId =
          cardtoCart.addCartCardPaymentMethod.cart.selectedItems[0]
            .availablePaymentMethods[0].id

        await selectCardMethod(appMasterData.cart.cartId, paymentMethodId)
      }

      // Call event horizon api
      if (isMembership && appMasterData.isAgree) {
        await agreeMembershipEventHorizon(
          appMasterData.userInfo.email,
          appMasterData.userInfo.phone_number,
          appMasterData.userTokenPayload.id,
          appMasterData.userInfo.boulevard_id,
          new Date(),
          window.location.hostname
        )
      }

      // Analytics
      await updateClientInfoAndCheckoutCard(tokenData)
    } catch (e: any) {
      const errorMessage = getErrorMessage(e?.error.message)
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      })
      Sentry.withScope((scope) => {
        scope.setUser({
          ...appMasterData.userInfo,
          isExistingCard: appointmentConfMasterData.isExistingCard,
        })
        Sentry.captureMessage(`Booking Failed: "${errorMessage}"`)
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button
      fullWidth
      variant="contained"
      disableElevation
      className={conditionalClasses(
        Boolean(isMembership),
        'membership-btn',
        '',
        'app-btn'
      )}
      disabled={
        (!appointmentConfMasterData.isExistingCard &&
          appointmentConfMasterData.newCardDisableStatus) ||
        checkIsLaserAndPreviousApp
      }
      onClick={() => {
        onConfirm().catch((e) => console.error(e))
      }}
    >
      {isMembership && <span>Finish & Pay</span>}
      {!isMembership && (
        <>
          {appMasterData.cart.purchasedMembership ? (
            <span>
              Confirm & Pay{' '}
              {formattedCost(appMasterData.membershipData.listPrice)} Today
            </span>
          ) : (
            <span>Confirm Appointment</span>
          )}
        </>
      )}
    </Button>
  )
}

export default React.memo(AppointmentConfirmBtn)
