export const TAG_ID_MAPPER = {
  development: {
    BI_MONTHLY: 'urn:blvd:Tag:693d0bff-3758-44e5-8c50-93ed2bfded07',
    MONTHLY: 'urn:blvd:Tag:71b6808b-7010-44d9-8928-a7cdf531a5a0',
  },
  staging: {
    BI_MONTHLY: 'urn:blvd:Tag:103bc809-0b7b-46e9-baf8-014b7af100a8',
    MONTHLY: 'urn:blvd:Tag:516a02e4-7692-497c-90da-476d75bc8e01',
  },
  production: {
    BI_MONTHLY: 'urn:blvd:Tag:103bc809-0b7b-46e9-baf8-014b7af100a8',
    MONTHLY: 'urn:blvd:Tag:516a02e4-7692-497c-90da-476d75bc8e01',
  },
}

export const MEMBERSHIP_TYPES = {
  MONTHLY: 'MONTHLY MEMBER',
  BI_MONTHLY: 'BI MONTHLY MEMBER',
}

export const FIFTY_PERCENT_DIVISOR = 2
export const THIRTY_PERCENT_DIVISOR = 3

export const BI_MONTHLY_MATCHERS = ['bi-monthly', 'bimonthly', 'bi monthly']
export const MEMBERSHIP_TITLE = 'Get The Glow Only Members Know'
export const MEMBERSHIP_DESCRIPTION =
  'Progress is the ultimate payoff. Stay on track with discounts and perks that make it easy to commit to yourself.'
export const MONTHLY_SECOND_FACIAL_PRICE = 8500
export const MONTHLY_FACIAL_NAME = '50 Minute Facial'
