import React, { Dispatch, SetStateAction, useContext } from 'react'
import { AppContext } from '../../../providers/context/App'
import './appointment.scss'
import AppointmentSubtotal from './appointment-subtotal'
import {
  BI_MONTHLY_FACIAL_BANNER_TEXT,
  MONTHLY_FACIAL_BANNER_TEXT,
  SUMMER_PROMO_DESCRIPTION,
} from 'utils/constants/Messages'
import { isEnableSummerPromo } from 'utils/helper-functions'
import useMembership from 'views/hooks/useMembership'
type Props = {
  setTotal: Dispatch<SetStateAction<number>>
}

const AppointmentPrice = ({ setTotal }: Props) => {
  const { appMasterData } = useContext(AppContext)
  const isLaserFacial = appMasterData.isLaserFacial
  const { isMember, isBiMonthlyMember } = useMembership()

  const marketingObj =
    appMasterData.location.marketingSupport.confirmationPromo ?? {}

  return (
    <div className="price-section">
      <AppointmentSubtotal setTotal={setTotal} />

      {isMember && !isLaserFacial && (
        <div className="member-text">
          <span className="body-2">
            {isBiMonthlyMember
              ? BI_MONTHLY_FACIAL_BANNER_TEXT
              : MONTHLY_FACIAL_BANNER_TEXT}
          </span>
        </div>
      )}

      {!isMember && !isLaserFacial && (
        <>
          {appMasterData.location.marketingSupport.enableNewClientPromo &&
            (!!marketingObj.headline || !!marketingObj.subheading) &&
            !appMasterData.isLoggedIn && (
              <div className="offer-wrapper">
                <div
                  className="offer-banner"
                  style={{ backgroundColor: marketingObj.bannerColor }}
                >
                  <div
                    className="body-1 offer-head"
                    style={{ color: marketingObj.textColor }}
                  >
                    <span className="bold">{marketingObj?.headline}</span>
                  </div>
                  <div
                    className="body-2"
                    style={{ color: marketingObj.textColor }}
                  >
                    <span>{marketingObj?.subheading}</span>
                  </div>
                </div>
              </div>
            )}
        </>
      )}
      {/* Summer Glow Promo Banner */}
      {!isLaserFacial &&
        isEnableSummerPromo(
          appMasterData.selectedDate,
          appMasterData.location.name
        ) && (
          <div className="summer-promo-banner">
            <div className="headline">
              One free {isMember ? '$80' : '$50'} value enhancement
            </div>
            <div className="description">
              {SUMMER_PROMO_DESCRIPTION(isMember)}
            </div>
          </div>
        )}
    </div>
  )
}

export default React.memo(AppointmentPrice)
