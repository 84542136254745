export const CUSTOMER_ACCESS_TOKEN = 'customer_access_token'
export const CUSTOMER_SESSION_TOKEN = 'customer_session_token'
export const BOULEVARD_PUBLIC_API_TOKEN = 'boulevard_public_api_token'
export const BOULEVARD_AUTH_API_TOKEN = 'boulevard_auth_api_token'
export const IS_BOULEVARD_CLIENT = 'is_boulevard_client'

//Routes constants
export const SIGNUP_ROUTE = '/sign-up'
export const WELCOME_ROUTE = '/welcome'
export const LOGIN_ROUTE = '/login'
export const LOGIN_ROUTE_CUSTOM = '/login/from/:path'
export const SIGNUP_ROUTE_CUSTOM = `${SIGNUP_ROUTE}/from/:path`
export const FORGET_ROUTE = '/forget'
export const FORGET_SUCCESS_ROUTE = '/forget-success'
export const LOCATIONS_ROUTE = '/locations'
export const ESTHETICIAN_LIST_ROUTE = '/esthetician-list'
export const ENHANCEMENT_ROUTE = '/enhancement'
export const HEALTH_CHECK_ROUTE = '/health-check'
export const APPOINTMENT_CONFIRMATION_ROUTE = '/appointment-confirmation'
export const CONFIRM_APPOINTMENT_ROUTE = '/confirmed-appointment'
export const SHARE_APPOINTMENT_ROUTE = '/share-appointment'
export const FORGET_ROUTE_CUSTOM = `${FORGET_ROUTE}/from/:path`
export const FORGET_SUCCESS_ROUTE_CUSTOM = `${FORGET_SUCCESS_ROUTE}/from/:path`

export const MEMBERSHIP = '/membership'
export const MEMBERSHIP_MAIN_ROUTE = `${MEMBERSHIP}/*`
export const MEMBERSHIP_APPOINTMENT_CONFIRMATION_ROUTE = `${MEMBERSHIP}/confirmation`
export const MEMBERSHIP_CONFIRMATION_ROUTE = '/confirmation'
export const MEMBERSHIP_EXISTING_USER = '/existing-user'
export const MEMBERSHIP_EXISTING_USER_MAIN_ROUTE = `${MEMBERSHIP}/existing-user`
export const MEMBERSHIP_NO_APPOINTMENT = '/no-appointment'
export const MEMBERSHIP_UPCOMING_APPOINTMENT = '/upcoming-appointment'
export const MEMBERSHIP_LOCATIONS_ROUTE = `${MEMBERSHIP}/locations`
export const GROUP_BOOKING = '/group-booking'
export const LASER = '/laser'
export const LASER_MAIN_ROUTE = `${LASER}/*`
export const LASER_ESTHETICIAN_LIST_ROUTE = `${LASER}/esthetician-list`
export const LASER_HEALTH_CHECK_ROUTE = `${LASER}/health-check`
export const LASER_APPOINTMENT_CONFIRMATION_ROUTE = `${LASER}/appointment-confirmation`
export const FACIALS_ROUTE = `/facials`
export const LASER_CONFIRM_APPOINTMENT_ROUTE = `${LASER}/confirmed-appointment`
export const BLVD_LOGIN_LINK = 'https://blvd.app/@heyday/login '

// Location state config
export const LOCATION_STATE_CONFIG: Record<string, string> = {
  CA: 'California',
  NY: 'New York',
  PA: 'Pennsylvania',
  MD: 'Maryland',
  VA: 'Virginia',
  GA: 'Georgia',
  IL: 'Illinois',
  TX: 'Texas',
  MI: 'Michigan',
  CO: 'Colorado',
  AZ: 'Arizona',
  DC: 'Washington, DC',
  MA: 'Massachusetts',
  MN: 'Minnesota',
  AL: 'Alabama',
  AK: 'Alaska',
  AR: 'Arkansas',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  HI: 'Hawaii',
  ID: 'Idaho',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  UT: 'Utah',
  VT: 'Vermont',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}

export const RETURNING_NON_MEMBER_BANNER_CITIES = [
  '14th St.',
  'Old Town Alexandria',
  'Bethesda Row',
  'Buckhead',
  'Midtown',
  'Perimeter',
  'Krog District',
  'East Cobb',
]

export const NO_TOKEN_FOUND = 'No Token Found'

export const NOMAD_DEV_LOCATION_ID =
  'urn:blvd:Location:c496ea57-0a49-473f-9558-8ffb1a10b68a'
export const UPPER_EAST_SIDE_LOCATION_ID =
  'urn:blvd:Location:49e97c04-1421-44a3-a37f-78fbfd8a87bb'

export const LASER_FACIAL_LOCATION =
  process.env.REACT_APP_ENV === 'development'
    ? NOMAD_DEV_LOCATION_ID
    : UPPER_EAST_SIDE_LOCATION_ID

export const AMEX_CARD = '9999 9999 9999 999'
export const NON_AMEX_CARD = '9999 9999 9999 9999'

export const GROUP_LIST = [
  {
    id: 1,
    title: 'Get everyone’s email.',
    subText:
      'We’ll need each person’s email address to book their appointment.',
  },
  {
    id: 2,
    title: 'Pick a date & time.',
    subText: 'We’ll help you plan a day that works for your group’s schedule!',
  },
  {
    id: 3,
    title: 'Call the shop.',
    subText: 'Share the details & our hosts will take care of the rest.',
  },
]

export const MEMBERSHIP_FACIAL_PRICE = 8500
export const MEMBERSHIP_MAX_FACIAL_DISCOUNT = 3100
export const MEMBERSHIP_LASER_FACIAL_PRICE = 225
export const ENABLE_MEMBERSHIP_UPSELL = true
export const BLACK_FRIDAY_PROMO_CODE = '20OFFFACIALHOLIDAY'
export const SAY_HEY_PROMO_CODE = 'SAYHEY40'
export const BLACK_FRIDAY_PROMO_START_DATE = '2023-11-24T05:00:00'
export const BLACK_FRIDAY_PROMO_END_DATE = '2023-11-28T05:00:00'
export const FACIAL_SERVICES_DESCRIPTION =
  'First time? Not sure what to pick? Try our 50 Minute Facial.'
export const FOUNDING_MEMBERSHIP_CITIES: string[] = []
export const FACIALS = ['Laser', '50 Minute']
export const DEFAULT_CURRENCY = 'USD'
export const facialPreCheck = {
  header: 'Before you book, have you recently had:',
  items: [
    'Botox or fillers',
    'Chemical peels',
    'Microneedling',
    'Microblading',
    'Dermaplaning',
    'Microdermabrasion',
    'Laser resurfacing/hair removal (face)',
    'Active/healing cold sore',
  ],
  footer: 'We ask you wait at least 14 days before getting your next facial.',
}

export const laserPreCheck = {
  header: 'Have you recently been treated with / for any of the following:',
  items: [
    'Accutane use within the past 6 months',
    'Botox, fillers, chemical peels, microblading or microneedling in the past 2 weeks',
    'Systemic steroid use',
    'Recent use of antibiotics',
    'Prescription anticoagulant',
    'Immunosuppressant medication',
    'Gold therapy (injection/oral)',
    'Impaired immune system/response',
    'History of skin cancer',
    'Lupus',
    'Vitiligo',
    'Seizure disorders',
    'Pregnant or trying to conceive, including IVF',
    'Recent sun exposure/tanning/sunless tanning within 1 week',
    'Active inflammatory disorders (eczema, rosacea, psoriasis, dermatitis)',
  ],
  footer:
    'A laser facial is not recommended at this time, but we are happy to recommend another treatment for you to reach your skin goals.',
}

export const MEMBERSHIP_CATEGORIES = ['Memberships', 'Products']
