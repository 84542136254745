import { Buffer } from 'buffer'
import {
  CUSTOMER_SESSION_TOKEN,
  NO_TOKEN_FOUND,
  FACIALS,
} from '../constants/Helpers'
import { REGEX } from '../constants/regex'
import { AppointmentConfirmationData } from '../types/appointmentTypes'
import { emailSchema } from '../yupSchema'
import { Location } from 'utils/types/appTypes'
import { AnalyticsLocationData } from 'utils/types/locationTypes'
import { GENERAL_APPOINTMENT_ERROR_MESSAGE } from 'utils/constants/Messages'
import moment from 'moment'
import { BookedAppointmentResponse } from 'utils/types/userTypes'
import { DISCOUNT } from 'utils/constants/Enums'

/**
 * @param apiKey - string
 *
 * Returns a token to access public client API
 * in a string.
 */

type publicClientToken = (apiKey: string) => string

export const generatePublicClientToken: publicClientToken = (apiKey) => {
  const payload = `${apiKey}:`
  const http_basic_credentials = Buffer.from(payload, 'utf8').toString('base64')

  return http_basic_credentials
}

/**
 * @param firstName - string
 * @param lastName - string
 *
 * Returns a avatar name first & surnames first letter
 * in a string.
 */

type generateAvatarTextType = (firstName: string, lastName: string) => string

export const generateAvatarText: generateAvatarTextType = (
  firstName,
  lastName
) => {
  return `${firstName.charAt(0).toLocaleUpperCase()}${lastName
    .charAt(0)
    .toLocaleUpperCase()}`
}

/**
 * @param keyName - string
 * @param value - string
 *
 * set localstorage item
 */
export const setLocalstorageItem = (keyName: string, value: string) => {
  window.localStorage.setItem(keyName, value)
}

/**
 * @param keyName - string
 *
 * Returns a localstorage value based on key name
 * in a string.
 */
export const getLocalstorageItem = (keyName: string) => {
  return window.localStorage.getItem(keyName)
}

export const removeLocalstorageItem = (keyName: string) => {
  return window.localStorage.removeItem(keyName)
}

export const emailVerifier = (emailId: string): boolean => {
  try {
    if (emailId) {
      emailSchema.validateSync(emailId)
      return true
    }
    return false
  } catch (error) {
    return false
  }
}
export const containSpecialChar = (name: string): boolean => {
  return REGEX.NAME.test(name)
}

export const textTrimmer = (data: string): string => {
  return data.trim()
}

export const numberOnly = (data: string): boolean => {
  return REGEX.PHONE_NUMBER.test(data)
}

export const cardTypeValidation = (data: string): string => {
  let cardNumber = data.replace(/[_ ]/g, '')
  let cardType = ''
  if (cardNumber) {
    if (REGEX.AMERICAN_EXP_CARD_REGEX.test(cardNumber)) {
      cardType = 'AMEX'
    } else {
      cardType = 'ERROR'
    }
  }
  return cardType
}

/**
 * @param condition - boolean that will be checked for response
 * @param truthyClass - class applied when the condition is true
 * @param falsyClass - class applied when the condition is false
 * @param commonClass - classes which are common in both condition
 *
 * Returns a string with the list of classes separated by spaces
 * in a string.
 */

type ConditionalClasses = (
  condition: boolean,
  truthyClass: string,
  falsyClass?: string,
  commonClass?: string
) => string

export const conditionalClasses: ConditionalClasses = (
  condition,
  truthyClass,
  falsyClass = '',
  commonClass = ''
) => {
  return `${commonClass} ${condition ? truthyClass : falsyClass}`
}

/**
 * @param cost - number
 * @param tailingZero - boolean
 * @param divider - number
 * Returns a formatted cost with $ value
 * in a string.
 */

type PriceFormatter = (
  cost: number,
  tailingZero?: boolean,
  divider?: number
) => string

export const formattedCost: PriceFormatter = (
  cost,
  tailingZero = true,
  divider = 100
) => {
  if (!cost) return '$0'
  const num = cost / divider // Divide the cost based on the requirements
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: tailingZero || num % 1 !== 0 ? 2 : 0,
  })

  return formatter.format(num)
}

export const tokenValidator = () => {
  const tokenValid = getLocalstorageItem(CUSTOMER_SESSION_TOKEN)
  if (!tokenValid) {
    throw new Error(NO_TOKEN_FOUND)
  }
}

export const findDateDifference = (futureDate: Date, currentDate: Date) => {
  const timeDifference = futureDate.getTime() - currentDate.getTime()
  const dateDiff = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
  return dateDiff
}

export const chooseToken = (
  appointmentConfMasterData: AppointmentConfirmationData,
  generateToken: string
) => {
  if (appointmentConfMasterData.isExistingCard) {
    return appointmentConfMasterData.selectedCardToken
      ? appointmentConfMasterData.selectedCardToken
      : generateToken
  } else {
    return generateToken
  }
}

export const getOpeningDate = (loc: Location) => {
  if (loc.openDate && new Date(loc.openDate) > new Date()) {
    return new Date(`${loc.openDate} 00:00`)
  }
}

export const getSelectedDate = (selectedDate: Date, loc: Location) => {
  const openingDate = getOpeningDate(loc)
  if (openingDate) {
    return openingDate > selectedDate ? openingDate : selectedDate
  }
  return selectedDate
}

export const getBannerText = (loc: Location) => {
  const openingDate = getOpeningDate(loc)
  if (openingDate) {
    return `Hey there! Our doors open on ${
      openingDate.getMonth() + 1
    }/${openingDate.getDate()}, so make sure to book after that date.`
  }
}

export const getFacialType = (facial: string) => {
  if (facial) {
    return `${facial} ${FACIALS.includes(facial) ? 'Facial' : ''}`
  } else {
    return `${FACIALS[1]} Facial`
  }
}

export const getLocationDataForAnalytics = (
  location: Location
): AnalyticsLocationData => {
  return {
    locationId: location.locationId,
    name: location.name,
    address: location.address,
  }
}

export const getErrorMessage = (message: string) => {
  if (!message) return GENERAL_APPOINTMENT_ERROR_MESSAGE
  return `${message} Please contact hello@heydayskincare.com if further assistance is needed.`
}

export const cleanPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) return ''
  // Remove non-numeric characters
  const cleanedNumber = phoneNumber.replace(/\D/g, '')
  // Check if the number already starts with '1'
  if (cleanedNumber.startsWith('1')) {
    return '+' + cleanedNumber
  }
  return '+1' + cleanedNumber
}

export const isEnableSummerPromo = (
  appointmentDate: Date | string,
  location: string
): boolean => {
  const excludedLocations = [
    'Lowry',
    'University Hills',
    'Tennyson',
    'Kirkland',
    'Plymouth Meeting',
    'Cobble Hill',
    'Woodbury',
  ]

  if (!appointmentDate) return false
  // Define the start and end dates of the range
  const startDate = moment('2024-06-11') // 2024-06-13 is actual date, 2 days buffer for testing
  const endDate = moment('2024-07-07')
  // Check if the appointment date is within the range
  return (
    moment(appointmentDate).isBetween(startDate, endDate, null, '[]') &&
    !excludedLocations.includes(location)
  )
}

export function getLatestAppointment(
  appointments: BookedAppointmentResponse[]
): BookedAppointmentResponse | null {
  if (!appointments.length) return null
  return (
    appointments.find((appointment) => appointment.state !== 'CANCELLED') ??
    null
  )
}

export function applyDiscount(amount: number, discount: DISCOUNT): number {
  if (discount < 0 || discount > 100) {
    throw new Error('Discount percentage not valid')
  }
  return amount - amount * (discount / 100)
}
