import { getMyMemberships } from 'services/User'
import { DISCOUNT, ENV } from 'utils/constants/Enums'
import { MEMBERSHIP_TYPES, TAG_ID_MAPPER } from 'utils/constants/Membership'
import { MembershipType, Tag } from 'utils/types/membershipTypes'
import {
  BookedAppointmentResponse,
  MembershipsEdge,
} from 'utils/types/userTypes'
import { applyDiscount } from '.'

export const getActiveMembershipDetails = async () => {
  try {
    const memberships = await getMyMemberships()
    if (memberships.myMemberships.edges.length)
      return memberships.myMemberships.edges.find(
        (obj) => obj.node.status === 'ACTIVE'
      )
  } catch (e) {
    console.error(e)
  }
}

export const getMyMembershipVouchers = (
  myActiveMembership?: MembershipsEdge
) => {
  try {
    if (myActiveMembership) return myActiveMembership.node.vouchers
  } catch (e) {
    console.error(e)
  }
}

export const getMembershipType = (tags: Tag[]): MembershipType => {
  if (!tags.length) return null

  const currentENV = process.env.REACT_APP_ENV as ENV
  const currentEnvTagID = TAG_ID_MAPPER[currentENV]
  let hasBiMonthly = false

  for (const tag of tags) {
    if (tag.source_tag_id === currentEnvTagID.MONTHLY) {
      return MEMBERSHIP_TYPES.MONTHLY
    }
    if (tag.source_tag_id === currentEnvTagID.BI_MONTHLY) {
      hasBiMonthly = true
    }
  }

  return hasBiMonthly ? MEMBERSHIP_TYPES.BI_MONTHLY : null
}

export const getEnhancementPrice = (
  enhancementPrice: number,
  isBiMonthly: boolean
): number => {
  return applyDiscount(
    enhancementPrice,
    isBiMonthly ? DISCOUNT.THIRTY : DISCOUNT.FIFTY
  )
}

export function memberSecondAppointmentCheck(
  activeMembership: MembershipsEdge | null,
  appointment: BookedAppointmentResponse | null
): boolean {
  if (!activeMembership) return false
  const startDate = new Date(activeMembership.node.startOn)
  const currentDate = new Date()
  const endDate = new Date(activeMembership.node.endOn)

  if (!appointment) {
    return false
  }

  const lastApptDate = new Date(appointment.start_at)
  return (
    currentDate < endDate && startDate <= lastApptDate && endDate > lastApptDate
  )
}
