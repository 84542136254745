const sentryIgnoreErrors: (RegExp | string)[] = [
  /ttq.methods.length is not iterable/i,
  /\bTest Error\b/i, // Ignoring this will prevent 'Test Errors' including events from being pushed to Sentry.
  /undefined is not an object (evaluating 'window.webkit.messageHandlers')"/i,
  /Unexpected token '<'/i,

  // New additions
  /TypeError: o.methods.length is not iterable/,
  /undefined is not an object (evaluating 't.tab.customFillData')/,
  /Maximum update depth exceeded/,
  /Loading CSS chunk 669 failed./,
]

export default sentryIgnoreErrors
